/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTestLambdaParameter = /* GraphQL */ `
  query GetTestLambdaParameter($id: ID!) {
    getTestLambdaParameter(id: $id) {
      id
      price
      calculatedPrice
      productprices
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTestLambdaParameters = /* GraphQL */ `
  query ListTestLambdaParameters(
    $filter: ModelTestLambdaParameterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestLambdaParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        price
        calculatedPrice
        productprices
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTestLambdaParameters = /* GraphQL */ `
  query SearchTestLambdaParameters(
    $filter: SearchableTestLambdaParameterFilterInput
    $sort: [SearchableTestLambdaParameterSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTestLambdaParameterAggregationInput]
  ) {
    searchTestLambdaParameters(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        price
        calculatedPrice
        productprices
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      type
      name
      email
      phone
      message
      status
      language
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        email
        phone
        message
        status
        language
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListContactsByDate = /* GraphQL */ `
  query ListContactsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListContactsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        email
        phone
        message
        status
        language
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchContacts = /* GraphQL */ `
  query SearchContacts(
    $filter: SearchableContactFilterInput
    $sort: [SearchableContactSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableContactAggregationInput]
  ) {
    searchContacts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        name
        email
        phone
        message
        status
        language
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCarouselSlide = /* GraphQL */ `
  query GetCarouselSlide($id: ID!) {
    getCarouselSlide(id: $id) {
      id
      lang
      sort
      description
      url
      path
      image
      s3key
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCarouselSlides = /* GraphQL */ `
  query ListCarouselSlides(
    $filter: ModelCarouselSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarouselSlides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lang
        sort
        description
        url
        path
        image
        s3key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CarouselSlidesByLangByDate = /* GraphQL */ `
  query CarouselSlidesByLangByDate(
    $lang: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CarouselSlidesByLangByDate(
      lang: $lang
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lang
        sort
        description
        url
        path
        image
        s3key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CarouselSlidesByLang = /* GraphQL */ `
  query CarouselSlidesByLang(
    $lang: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselSlideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CarouselSlidesByLang(
      lang: $lang
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lang
        sort
        description
        url
        path
        image
        s3key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCarouselSlideMultilanguage = /* GraphQL */ `
  query GetCarouselSlideMultilanguage($id: ID!) {
    getCarouselSlideMultilanguage(id: $id) {
      id
      sort
      type
      description
      url
      path
      image
      s3key
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCarouselSlideMultilanguages = /* GraphQL */ `
  query ListCarouselSlideMultilanguages(
    $filter: ModelCarouselSlideMultilanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarouselSlideMultilanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sort
        type
        description
        url
        path
        image
        s3key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CarouselSlideMultilanguageBySortByDate = /* GraphQL */ `
  query CarouselSlideMultilanguageBySortByDate(
    $sort: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselSlideMultilanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CarouselSlideMultilanguageBySortByDate(
      sort: $sort
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sort
        type
        description
        url
        path
        image
        s3key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CarouselSlideMultilanguageByDate = /* GraphQL */ `
  query CarouselSlideMultilanguageByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCarouselSlideMultilanguageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CarouselSlideMultilanguageByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sort
        type
        description
        url
        path
        image
        s3key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExchangeRate = /* GraphQL */ `
  query GetExchangeRate($id: ID!) {
    getExchangeRate(id: $id) {
      id
      base
      to
      rate
      day
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listExchangeRates = /* GraphQL */ `
  query ListExchangeRates(
    $filter: ModelExchangeRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExchangeRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        base
        to
        rate
        day
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ExchangeRateByBaseByDay = /* GraphQL */ `
  query ExchangeRateByBaseByDay(
    $base: String!
    $day: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ExchangeRateByBaseByDay(
      base: $base
      day: $day
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        base
        to
        rate
        day
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ExchangeRateByDay = /* GraphQL */ `
  query ExchangeRateByDay(
    $day: String!
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ExchangeRateByDay(
      day: $day
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        base
        to
        rate
        day
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExchangeRateManual = /* GraphQL */ `
  query GetExchangeRateManual($id: ID!) {
    getExchangeRateManual(id: $id) {
      id
      base
      to
      rate
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listExchangeRateManuals = /* GraphQL */ `
  query ListExchangeRateManuals(
    $filter: ModelExchangeRateManualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExchangeRateManuals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        base
        to
        rate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ExchangeRateManualByBase = /* GraphQL */ `
  query ExchangeRateManualByBase(
    $base: String!
    $sortDirection: ModelSortDirection
    $filter: ModelExchangeRateManualFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ExchangeRateManualByBase(
      base: $base
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        base
        to
        rate
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductAttributeLock = /* GraphQL */ `
  query GetProductAttributeLock($id: ID!) {
    getProductAttributeLock(id: $id) {
      id
      lockedAttribs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductAttributeLocks = /* GraphQL */ `
  query ListProductAttributeLocks(
    $filter: ModelProductAttributeLockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductAttributeLocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lockedAttribs
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductOneAttributeLock = /* GraphQL */ `
  query GetProductOneAttributeLock($id: ID!) {
    getProductOneAttributeLock(id: $id) {
      id
      ProductID
      lockedAttribute
      product {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProductOneAttributeLocks = /* GraphQL */ `
  query ListProductOneAttributeLocks(
    $filter: ModelProductOneAttributeLockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductOneAttributeLocks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        lockedAttribute
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductOneAttributeLockByProduct = /* GraphQL */ `
  query ProductOneAttributeLockByProduct(
    $ProductID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductOneAttributeLockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductOneAttributeLockByProduct(
      ProductID: $ProductID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        lockedAttribute
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      type
      createdAt
      updatedAt
      manualUpdatedAt
      ean
      name
      image
      mainImage
      mainImageOriginalWidth
      mainImageOriginalHeight
      mainImageUpdatedAt
      moreImages
      author
      binding
      bic
      countryOfOrigin
      editor
      illustrator
      edition
      illustrations
      coPublisherName
      languageOfBook
      mixedPackComponents
      nationalCurriculumKeyStage
      publicationDate
      pagination
      numberOfPages
      price
      calculatedPrices
      priceUpdatedAt
      currency
      salePercent
      salePercentWhileStocksLast
      salePercentUpdatedAt
      pageSizeDimensions
      publisherName
      yearOfPublication
      series
      seriesEditor
      subTitle
      titlePrefix
      partTitle
      translator
      partNumber
      shortDescription
      shortDescriptionTranslationHu
      returnsFlag
      returnsDate
      newIsbn
      weight
      weightMetric
      sizeDimensionX
      sizeDimensionY
      sizeDimensionZ
      orderable
      orderableInfo
      orderableDataUpdatedAt
      orderableFlag
      oldShopItemsItemId
      htmlHeadDescriptionOverwrite
      htmlHeadKeywordsAdded
      searchText
      searchTextReverse
      searchTextCategorys
      searchTextFlags
      productTemplate
      deleted
      deletedAt
      disabledSalesChannels
      categorys {
        items {
          id
          ProductID
          categoryID
          productPrice
          productPublicationDate
          productOrderable
          productOrderableInfo
          productSalePercent
          type
          createdAt
          updatedAt
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      inventories {
        items {
          productID
          warehouseID
          inventoryAmount
          warehouse {
            id
            name
            type
            createdAt
            updatedAt
            owner
            __typename
          }
          inventoryModifiedBy
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          productId
          tagId
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tag {
            id
            tag
            products {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      producthistories {
        items {
          id
          type
          productId
          createdAt
          updatedAt
          ean
          name
          image
          mainImage
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          currency
          salePercent
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          productTemplate
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      lockedattribs {
        items {
          id
          ProductID
          lockedAttribute
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductByManualUpdatedAt = /* GraphQL */ `
  query ProductByManualUpdatedAt(
    $type: String!
    $manualUpdatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductByManualUpdatedAt(
      type: $type
      manualUpdatedAt: $manualUpdatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductByEan = /* GraphQL */ `
  query ProductByEan(
    $ean: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductByEan(
      ean: $ean
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: [SearchableProductSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductAggregationInput]
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getProductHistory = /* GraphQL */ `
  query GetProductHistory($id: ID!) {
    getProductHistory(id: $id) {
      id
      type
      productId
      createdAt
      updatedAt
      ean
      name
      image
      mainImage
      moreImages
      author
      binding
      bic
      countryOfOrigin
      editor
      illustrator
      edition
      illustrations
      coPublisherName
      languageOfBook
      mixedPackComponents
      nationalCurriculumKeyStage
      publicationDate
      pagination
      numberOfPages
      price
      currency
      salePercent
      pageSizeDimensions
      publisherName
      yearOfPublication
      series
      seriesEditor
      subTitle
      titlePrefix
      partTitle
      translator
      partNumber
      shortDescription
      shortDescriptionTranslationHu
      returnsFlag
      returnsDate
      newIsbn
      weight
      weightMetric
      sizeDimensionX
      sizeDimensionY
      sizeDimensionZ
      orderable
      orderableInfo
      oldShopItemsItemId
      htmlHeadDescriptionOverwrite
      htmlHeadKeywordsAdded
      searchText
      searchTextReverse
      productTemplate
      product {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listProductHistories = /* GraphQL */ `
  query ListProductHistories(
    $filter: ModelProductHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        productId
        createdAt
        updatedAt
        ean
        name
        image
        mainImage
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        currency
        salePercent
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        productTemplate
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductHistoryByProductId = /* GraphQL */ `
  query ProductHistoryByProductId(
    $productId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductHistoryByProductId(
      productId: $productId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        productId
        createdAt
        updatedAt
        ean
        name
        image
        mainImage
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        currency
        salePercent
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        productTemplate
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductAttributeV2 = /* GraphQL */ `
  query GetProductAttributeV2($id: ID!) {
    getProductAttributeV2(id: $id) {
      id
      createdAt
      updatedAt
      manualUpdatedAt
      manualUpdatedBy
      attributeTableName
      attributeName
      attributeType
      enabled
      owner
      __typename
    }
  }
`;
export const listProductAttributeV2s = /* GraphQL */ `
  query ListProductAttributeV2s(
    $filter: ModelProductAttributeV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductAttributeV2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        manualUpdatedAt
        manualUpdatedBy
        attributeTableName
        attributeName
        attributeType
        enabled
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductV2 = /* GraphQL */ `
  query GetProductV2($id: ID!) {
    getProductV2(id: $id) {
      id
      type
      createdAt
      updatedAt
      manualUpdatedAt
      manualUpdatedBy
      ean
      sku
      name
      attributeString1
      attributeString2
      attributeString3
      attributeString4
      attributeString5
      attributeString6
      attributeString7
      attributeString8
      attributeString9
      attributeString10
      attributeString11
      attributeString12
      attributeString13
      attributeString14
      attributeString15
      attributeString16
      attributeString17
      attributeString18
      attributeString19
      attributeString20
      attributeString21
      attributeString22
      attributeString23
      attributeString24
      attributeString25
      attributeString26
      attributeString27
      attributeString28
      attributeString29
      attributeString30
      attributeString31
      attributeString32
      attributeString33
      attributeString34
      attributeString35
      attributeString36
      attributeString37
      attributeString38
      attributeString39
      attributeString40
      attributeString41
      attributeString42
      attributeString43
      attributeString44
      attributeString45
      attributeString46
      attributeString47
      attributeString48
      attributeString49
      attributeString50
      attributeDate1
      attributeDate2
      attributeDate3
      attributeDate4
      attributeDate5
      attributeDate6
      attributeDate7
      attributeDate8
      attributeDate9
      attributeDate10
      attributeDateTime1
      attributeDateTime2
      attributeDateTime3
      attributeDateTime4
      attributeDateTime5
      attributeDateTime6
      attributeDateTime7
      attributeDateTime8
      attributeDateTime9
      attributeDateTime10
      attributeFloat1
      attributeFloat2
      attributeFloat3
      attributeFloat4
      attributeFloat5
      attributeFloat6
      attributeFloat7
      attributeFloat8
      attributeFloat9
      attributeFloat10
      attributeInt1
      attributeInt2
      attributeInt3
      attributeInt4
      attributeInt5
      attributeInt6
      attributeInt7
      attributeInt8
      attributeInt9
      attributeInt10
      attributeInt11
      attributeInt12
      attributeInt13
      attributeInt14
      attributeInt15
      attributeInt16
      attributeInt17
      attributeInt18
      attributeInt19
      attributeInt20
      attributeInt21
      attributeInt22
      attributeInt23
      attributeInt24
      attributeInt25
      attributeInt26
      attributeInt27
      attributeInt28
      attributeInt29
      attributeInt30
      attributeBoolean1
      attributeBoolean2
      attributeBoolean3
      attributeBoolean4
      attributeBoolean5
      attributeBoolean6
      attributeBoolean7
      attributeBoolean8
      attributeBoolean9
      attributeBoolean10
      attributeBoolean11
      attributeBoolean12
      attributeBoolean13
      attributeBoolean14
      attributeBoolean15
      attributeBoolean16
      attributeBoolean17
      attributeBoolean18
      attributeBoolean19
      attributeBoolean20
      attributeCurrency1
      attributeCurrency2
      attributeCurrency3
      attributeCurrency4
      attributeCurrency5
      attributeCustom1
      owner
      __typename
    }
  }
`;
export const listProductV2s = /* GraphQL */ `
  query ListProductV2s(
    $filter: ModelProductV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductV2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        manualUpdatedBy
        ean
        sku
        name
        attributeString1
        attributeString2
        attributeString3
        attributeString4
        attributeString5
        attributeString6
        attributeString7
        attributeString8
        attributeString9
        attributeString10
        attributeString11
        attributeString12
        attributeString13
        attributeString14
        attributeString15
        attributeString16
        attributeString17
        attributeString18
        attributeString19
        attributeString20
        attributeString21
        attributeString22
        attributeString23
        attributeString24
        attributeString25
        attributeString26
        attributeString27
        attributeString28
        attributeString29
        attributeString30
        attributeString31
        attributeString32
        attributeString33
        attributeString34
        attributeString35
        attributeString36
        attributeString37
        attributeString38
        attributeString39
        attributeString40
        attributeString41
        attributeString42
        attributeString43
        attributeString44
        attributeString45
        attributeString46
        attributeString47
        attributeString48
        attributeString49
        attributeString50
        attributeDate1
        attributeDate2
        attributeDate3
        attributeDate4
        attributeDate5
        attributeDate6
        attributeDate7
        attributeDate8
        attributeDate9
        attributeDate10
        attributeDateTime1
        attributeDateTime2
        attributeDateTime3
        attributeDateTime4
        attributeDateTime5
        attributeDateTime6
        attributeDateTime7
        attributeDateTime8
        attributeDateTime9
        attributeDateTime10
        attributeFloat1
        attributeFloat2
        attributeFloat3
        attributeFloat4
        attributeFloat5
        attributeFloat6
        attributeFloat7
        attributeFloat8
        attributeFloat9
        attributeFloat10
        attributeInt1
        attributeInt2
        attributeInt3
        attributeInt4
        attributeInt5
        attributeInt6
        attributeInt7
        attributeInt8
        attributeInt9
        attributeInt10
        attributeInt11
        attributeInt12
        attributeInt13
        attributeInt14
        attributeInt15
        attributeInt16
        attributeInt17
        attributeInt18
        attributeInt19
        attributeInt20
        attributeInt21
        attributeInt22
        attributeInt23
        attributeInt24
        attributeInt25
        attributeInt26
        attributeInt27
        attributeInt28
        attributeInt29
        attributeInt30
        attributeBoolean1
        attributeBoolean2
        attributeBoolean3
        attributeBoolean4
        attributeBoolean5
        attributeBoolean6
        attributeBoolean7
        attributeBoolean8
        attributeBoolean9
        attributeBoolean10
        attributeBoolean11
        attributeBoolean12
        attributeBoolean13
        attributeBoolean14
        attributeBoolean15
        attributeBoolean16
        attributeBoolean17
        attributeBoolean18
        attributeBoolean19
        attributeBoolean20
        attributeCurrency1
        attributeCurrency2
        attributeCurrency3
        attributeCurrency4
        attributeCurrency5
        attributeCustom1
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductV2ByEan = /* GraphQL */ `
  query ProductV2ByEan(
    $ean: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductV2ByEan(
      ean: $ean
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        manualUpdatedBy
        ean
        sku
        name
        attributeString1
        attributeString2
        attributeString3
        attributeString4
        attributeString5
        attributeString6
        attributeString7
        attributeString8
        attributeString9
        attributeString10
        attributeString11
        attributeString12
        attributeString13
        attributeString14
        attributeString15
        attributeString16
        attributeString17
        attributeString18
        attributeString19
        attributeString20
        attributeString21
        attributeString22
        attributeString23
        attributeString24
        attributeString25
        attributeString26
        attributeString27
        attributeString28
        attributeString29
        attributeString30
        attributeString31
        attributeString32
        attributeString33
        attributeString34
        attributeString35
        attributeString36
        attributeString37
        attributeString38
        attributeString39
        attributeString40
        attributeString41
        attributeString42
        attributeString43
        attributeString44
        attributeString45
        attributeString46
        attributeString47
        attributeString48
        attributeString49
        attributeString50
        attributeDate1
        attributeDate2
        attributeDate3
        attributeDate4
        attributeDate5
        attributeDate6
        attributeDate7
        attributeDate8
        attributeDate9
        attributeDate10
        attributeDateTime1
        attributeDateTime2
        attributeDateTime3
        attributeDateTime4
        attributeDateTime5
        attributeDateTime6
        attributeDateTime7
        attributeDateTime8
        attributeDateTime9
        attributeDateTime10
        attributeFloat1
        attributeFloat2
        attributeFloat3
        attributeFloat4
        attributeFloat5
        attributeFloat6
        attributeFloat7
        attributeFloat8
        attributeFloat9
        attributeFloat10
        attributeInt1
        attributeInt2
        attributeInt3
        attributeInt4
        attributeInt5
        attributeInt6
        attributeInt7
        attributeInt8
        attributeInt9
        attributeInt10
        attributeInt11
        attributeInt12
        attributeInt13
        attributeInt14
        attributeInt15
        attributeInt16
        attributeInt17
        attributeInt18
        attributeInt19
        attributeInt20
        attributeInt21
        attributeInt22
        attributeInt23
        attributeInt24
        attributeInt25
        attributeInt26
        attributeInt27
        attributeInt28
        attributeInt29
        attributeInt30
        attributeBoolean1
        attributeBoolean2
        attributeBoolean3
        attributeBoolean4
        attributeBoolean5
        attributeBoolean6
        attributeBoolean7
        attributeBoolean8
        attributeBoolean9
        attributeBoolean10
        attributeBoolean11
        attributeBoolean12
        attributeBoolean13
        attributeBoolean14
        attributeBoolean15
        attributeBoolean16
        attributeBoolean17
        attributeBoolean18
        attributeBoolean19
        attributeBoolean20
        attributeCurrency1
        attributeCurrency2
        attributeCurrency3
        attributeCurrency4
        attributeCurrency5
        attributeCustom1
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductV2BySku = /* GraphQL */ `
  query ProductV2BySku(
    $sku: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductV2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductV2BySku(
      sku: $sku
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        manualUpdatedBy
        ean
        sku
        name
        attributeString1
        attributeString2
        attributeString3
        attributeString4
        attributeString5
        attributeString6
        attributeString7
        attributeString8
        attributeString9
        attributeString10
        attributeString11
        attributeString12
        attributeString13
        attributeString14
        attributeString15
        attributeString16
        attributeString17
        attributeString18
        attributeString19
        attributeString20
        attributeString21
        attributeString22
        attributeString23
        attributeString24
        attributeString25
        attributeString26
        attributeString27
        attributeString28
        attributeString29
        attributeString30
        attributeString31
        attributeString32
        attributeString33
        attributeString34
        attributeString35
        attributeString36
        attributeString37
        attributeString38
        attributeString39
        attributeString40
        attributeString41
        attributeString42
        attributeString43
        attributeString44
        attributeString45
        attributeString46
        attributeString47
        attributeString48
        attributeString49
        attributeString50
        attributeDate1
        attributeDate2
        attributeDate3
        attributeDate4
        attributeDate5
        attributeDate6
        attributeDate7
        attributeDate8
        attributeDate9
        attributeDate10
        attributeDateTime1
        attributeDateTime2
        attributeDateTime3
        attributeDateTime4
        attributeDateTime5
        attributeDateTime6
        attributeDateTime7
        attributeDateTime8
        attributeDateTime9
        attributeDateTime10
        attributeFloat1
        attributeFloat2
        attributeFloat3
        attributeFloat4
        attributeFloat5
        attributeFloat6
        attributeFloat7
        attributeFloat8
        attributeFloat9
        attributeFloat10
        attributeInt1
        attributeInt2
        attributeInt3
        attributeInt4
        attributeInt5
        attributeInt6
        attributeInt7
        attributeInt8
        attributeInt9
        attributeInt10
        attributeInt11
        attributeInt12
        attributeInt13
        attributeInt14
        attributeInt15
        attributeInt16
        attributeInt17
        attributeInt18
        attributeInt19
        attributeInt20
        attributeInt21
        attributeInt22
        attributeInt23
        attributeInt24
        attributeInt25
        attributeInt26
        attributeInt27
        attributeInt28
        attributeInt29
        attributeInt30
        attributeBoolean1
        attributeBoolean2
        attributeBoolean3
        attributeBoolean4
        attributeBoolean5
        attributeBoolean6
        attributeBoolean7
        attributeBoolean8
        attributeBoolean9
        attributeBoolean10
        attributeBoolean11
        attributeBoolean12
        attributeBoolean13
        attributeBoolean14
        attributeBoolean15
        attributeBoolean16
        attributeBoolean17
        attributeBoolean18
        attributeBoolean19
        attributeBoolean20
        attributeCurrency1
        attributeCurrency2
        attributeCurrency3
        attributeCurrency4
        attributeCurrency5
        attributeCustom1
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProductV2s = /* GraphQL */ `
  query SearchProductV2s(
    $filter: SearchableProductV2FilterInput
    $sort: [SearchableProductV2SortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductV2AggregationInput]
  ) {
    searchProductV2s(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        manualUpdatedBy
        ean
        sku
        name
        attributeString1
        attributeString2
        attributeString3
        attributeString4
        attributeString5
        attributeString6
        attributeString7
        attributeString8
        attributeString9
        attributeString10
        attributeString11
        attributeString12
        attributeString13
        attributeString14
        attributeString15
        attributeString16
        attributeString17
        attributeString18
        attributeString19
        attributeString20
        attributeString21
        attributeString22
        attributeString23
        attributeString24
        attributeString25
        attributeString26
        attributeString27
        attributeString28
        attributeString29
        attributeString30
        attributeString31
        attributeString32
        attributeString33
        attributeString34
        attributeString35
        attributeString36
        attributeString37
        attributeString38
        attributeString39
        attributeString40
        attributeString41
        attributeString42
        attributeString43
        attributeString44
        attributeString45
        attributeString46
        attributeString47
        attributeString48
        attributeString49
        attributeString50
        attributeDate1
        attributeDate2
        attributeDate3
        attributeDate4
        attributeDate5
        attributeDate6
        attributeDate7
        attributeDate8
        attributeDate9
        attributeDate10
        attributeDateTime1
        attributeDateTime2
        attributeDateTime3
        attributeDateTime4
        attributeDateTime5
        attributeDateTime6
        attributeDateTime7
        attributeDateTime8
        attributeDateTime9
        attributeDateTime10
        attributeFloat1
        attributeFloat2
        attributeFloat3
        attributeFloat4
        attributeFloat5
        attributeFloat6
        attributeFloat7
        attributeFloat8
        attributeFloat9
        attributeFloat10
        attributeInt1
        attributeInt2
        attributeInt3
        attributeInt4
        attributeInt5
        attributeInt6
        attributeInt7
        attributeInt8
        attributeInt9
        attributeInt10
        attributeInt11
        attributeInt12
        attributeInt13
        attributeInt14
        attributeInt15
        attributeInt16
        attributeInt17
        attributeInt18
        attributeInt19
        attributeInt20
        attributeInt21
        attributeInt22
        attributeInt23
        attributeInt24
        attributeInt25
        attributeInt26
        attributeInt27
        attributeInt28
        attributeInt29
        attributeInt30
        attributeBoolean1
        attributeBoolean2
        attributeBoolean3
        attributeBoolean4
        attributeBoolean5
        attributeBoolean6
        attributeBoolean7
        attributeBoolean8
        attributeBoolean9
        attributeBoolean10
        attributeBoolean11
        attributeBoolean12
        attributeBoolean13
        attributeBoolean14
        attributeBoolean15
        attributeBoolean16
        attributeBoolean17
        attributeBoolean18
        attributeBoolean19
        attributeBoolean20
        attributeCurrency1
        attributeCurrency2
        attributeCurrency3
        attributeCurrency4
        attributeCurrency5
        attributeCustom1
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($productID: ID!, $warehouseID: ID!) {
    getInventory(productID: $productID, warehouseID: $warehouseID) {
      productID
      warehouseID
      inventoryAmount
      warehouse {
        id
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      inventoryModifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories(
    $productID: ID
    $warehouseID: ModelIDKeyConditionInput
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInventories(
      productID: $productID
      warehouseID: $warehouseID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        productID
        warehouseID
        inventoryAmount
        warehouse {
          id
          name
          type
          createdAt
          updatedAt
          owner
          __typename
        }
        inventoryModifiedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ItemsByWarehouseID = /* GraphQL */ `
  query ItemsByWarehouseID(
    $warehouseID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ItemsByWarehouseID(
      warehouseID: $warehouseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        productID
        warehouseID
        inventoryAmount
        warehouse {
          id
          name
          type
          createdAt
          updatedAt
          owner
          __typename
        }
        inventoryModifiedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventoryHistory = /* GraphQL */ `
  query GetInventoryHistory($id: ID!) {
    getInventoryHistory(id: $id) {
      id
      type
      productID
      warehouseID
      inventoryAmount
      inventoryModifiedBy
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listInventoryHistories = /* GraphQL */ `
  query ListInventoryHistories(
    $filter: ModelInventoryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventoryHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        productID
        warehouseID
        inventoryAmount
        inventoryModifiedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListInventoryHistoryByProductByCreatedAt = /* GraphQL */ `
  query ListInventoryHistoryByProductByCreatedAt(
    $productID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInventoryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListInventoryHistoryByProductByCreatedAt(
      productID: $productID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        productID
        warehouseID
        inventoryAmount
        inventoryModifiedBy
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($id: ID!) {
    getWarehouse(id: $id) {
      id
      name
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listWarehouses = /* GraphQL */ `
  query ListWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarehouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WarehouseByType = /* GraphQL */ `
  query WarehouseByType(
    $type: WarehouseType!
    $sortDirection: ModelSortDirection
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WarehouseByType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductCategory = /* GraphQL */ `
  query GetProductCategory($id: ID!) {
    getProductCategory(id: $id) {
      id
      ProductID
      categoryID
      productPrice
      productPublicationDate
      productOrderable
      productOrderableInfo
      productSalePercent
      type
      createdAt
      updatedAt
      product {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      category {
        id
        name
        numberOfProducts
        createdAt
        updatedAt
        products {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        webshopmenus {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        categoryID
        productPrice
        productPublicationDate
        productOrderable
        productOrderableInfo
        productSalePercent
        type
        createdAt
        updatedAt
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductCategoryByProduct = /* GraphQL */ `
  query ProductCategoryByProduct(
    $ProductID: ID!
    $categoryID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductCategoryByProduct(
      ProductID: $ProductID
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        categoryID
        productPrice
        productPublicationDate
        productOrderable
        productOrderableInfo
        productSalePercent
        type
        createdAt
        updatedAt
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductCategoryByCategory = /* GraphQL */ `
  query ProductCategoryByCategory(
    $categoryID: ID!
    $ProductID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductCategoryByCategory(
      categoryID: $categoryID
      ProductID: $ProductID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        categoryID
        productPrice
        productPublicationDate
        productOrderable
        productOrderableInfo
        productSalePercent
        type
        createdAt
        updatedAt
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductCategoryByProductPrice = /* GraphQL */ `
  query ProductCategoryByProductPrice(
    $categoryID: ID!
    $productPrice: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductCategoryByProductPrice(
      categoryID: $categoryID
      productPrice: $productPrice
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        categoryID
        productPrice
        productPublicationDate
        productOrderable
        productOrderableInfo
        productSalePercent
        type
        createdAt
        updatedAt
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductCategoryByProductPublicationDate = /* GraphQL */ `
  query ProductCategoryByProductPublicationDate(
    $categoryID: ID!
    $productPublicationDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductCategoryByProductPublicationDate(
      categoryID: $categoryID
      productPublicationDate: $productPublicationDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductID
        categoryID
        productPrice
        productPublicationDate
        productOrderable
        productOrderableInfo
        productSalePercent
        type
        createdAt
        updatedAt
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      numberOfProducts
      createdAt
      updatedAt
      products {
        items {
          id
          ProductID
          categoryID
          productPrice
          productPublicationDate
          productOrderable
          productOrderableInfo
          productSalePercent
          type
          createdAt
          updatedAt
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      webshopmenus {
        items {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        numberOfProducts
        createdAt
        updatedAt
        products {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        webshopmenus {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      company
      firstName
      lastName
      email
      customerType
      taxId
      taxIdEu
      phone
      language
      ordersByDate {
        items {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      ordersByStatusDate {
        items {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        ordersByDate {
          items {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        ordersByStatusDate {
          items {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: [SearchableCustomerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomerAggregationInput]
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        ordersByDate {
          items {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        ordersByStatusDate {
          items {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      type
      specialOrderId
      customerId
      createdAt
      updatedAt
      total
      subtotal
      tax
      currency
      status
      company
      firstName
      lastName
      email
      customerType
      taxId
      taxIdEu
      phone
      language
      shippingCost
      shippingCurrency
      shippingType
      shippingName
      shippingZip
      shippingCity
      shippingStreet
      shippingStreetType
      shippingHouseNumber
      shippingHouseNumberInfo
      shippingCountry
      shippingCountryCode
      shippingComment
      billingType
      billingName
      billingZip
      billingCity
      billingStreet
      billingStreetType
      billingHouseNumber
      billingCountry
      billingCountryCode
      paymentStatus
      paymentType
      shippingStatus
      customer {
        id
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        ordersByDate {
          items {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        ordersByStatusDate {
          items {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      lineItems {
        items {
          id
          orderId
          productId
          productName
          qty
          qtyName
          warehouseId
          description
          price
          total
          vat
          currency
          order {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          warehouse {
            id
            name
            type
            createdAt
            updatedAt
            owner
            __typename
          }
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      invoices {
        items {
          id
          orderId
          type
          createdAt
          updatedAt
          provider
          invoiceContent
          status
          order {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      transactions {
        items {
          id
          paymentId
          orderId
          type
          createdAt
          updatedAt
          provider
          responseContentJson
          responseMessage
          responseHttpCode
          status
          order {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      adminComments {
        items {
          id
          orderId
          type
          createdAt
          updatedAt
          comment
          order {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      emailNotifications {
        items {
          id
          orderId
          type
          createdAt
          updatedAt
          notificationType
          mailFrom
          mailTo
          sesId
          order {
            id
            type
            specialOrderId
            customerId
            createdAt
            updatedAt
            total
            subtotal
            tax
            currency
            status
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            shippingCost
            shippingCurrency
            shippingType
            shippingName
            shippingZip
            shippingCity
            shippingStreet
            shippingStreetType
            shippingHouseNumber
            shippingHouseNumberInfo
            shippingCountry
            shippingCountryCode
            shippingComment
            billingType
            billingName
            billingZip
            billingCity
            billingStreet
            billingStreetType
            billingHouseNumber
            billingCountry
            billingCountryCode
            paymentStatus
            paymentType
            shippingStatus
            customer {
              id
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              createdAt
              updatedAt
              owner
              __typename
            }
            lineItems {
              nextToken
              __typename
            }
            invoices {
              nextToken
              __typename
            }
            transactions {
              nextToken
              __typename
            }
            adminComments {
              nextToken
              __typename
            }
            emailNotifications {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListOrdersByDate = /* GraphQL */ `
  query ListOrdersByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListOrdersByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListOrdersBySpecialOrderId = /* GraphQL */ `
  query ListOrdersBySpecialOrderId(
    $specialOrderId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListOrdersBySpecialOrderId(
      specialOrderId: $specialOrderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListOrdersByCustomerByDate = /* GraphQL */ `
  query ListOrdersByCustomerByDate(
    $customerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListOrdersByCustomerByDate(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListOrdersByStatusByDate = /* GraphQL */ `
  query ListOrdersByStatusByDate(
    $status: OrderStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListOrdersByStatusByDate(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: [SearchableOrderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrderAggregationInput]
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getShippingGls = /* GraphQL */ `
  query GetShippingGls($id: ID!) {
    getShippingGls(id: $id) {
      id
      orderId
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listShippingGls = /* GraphQL */ `
  query ListShippingGls(
    $filter: ModelShippingGlsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingGls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ShippingGlsByOrderIdAndDate = /* GraphQL */ `
  query ShippingGlsByOrderIdAndDate(
    $orderId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShippingGlsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ShippingGlsByOrderIdAndDate(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDashboardSettings = /* GraphQL */ `
  query GetDashboardSettings($settingsId: ID!, $userId: ID!) {
    getDashboardSettings(settingsId: $settingsId, userId: $userId) {
      settingsId
      userId
      settingsAdminName
      settingsAdminDescription
      settings
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDashboardSettings = /* GraphQL */ `
  query ListDashboardSettings(
    $settingsId: ID
    $userId: ModelIDKeyConditionInput
    $filter: ModelDashboardSettingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDashboardSettings(
      settingsId: $settingsId
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        settingsId
        userId
        settingsAdminName
        settingsAdminDescription
        settings
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminComment = /* GraphQL */ `
  query GetAdminComment($id: ID!) {
    getAdminComment(id: $id) {
      id
      orderId
      type
      createdAt
      updatedAt
      comment
      order {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listAdminComments = /* GraphQL */ `
  query ListAdminComments(
    $filter: ModelAdminCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        comment
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const AdminCommentListByOrderIdAndDate = /* GraphQL */ `
  query AdminCommentListByOrderIdAndDate(
    $orderId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AdminCommentListByOrderIdAndDate(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        comment
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailNotification = /* GraphQL */ `
  query GetEmailNotification($id: ID!) {
    getEmailNotification(id: $id) {
      id
      orderId
      type
      createdAt
      updatedAt
      notificationType
      mailFrom
      mailTo
      sesId
      order {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listEmailNotifications = /* GraphQL */ `
  query ListEmailNotifications(
    $filter: ModelEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        notificationType
        mailFrom
        mailTo
        sesId
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const EmailNotificationListByOrderIdAndDate = /* GraphQL */ `
  query EmailNotificationListByOrderIdAndDate(
    $orderId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEmailNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    EmailNotificationListByOrderIdAndDate(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        notificationType
        mailFrom
        mailTo
        sesId
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      paymentId
      orderId
      type
      createdAt
      updatedAt
      provider
      responseContentJson
      responseMessage
      responseHttpCode
      status
      order {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        paymentId
        orderId
        type
        createdAt
        updatedAt
        provider
        responseContentJson
        responseMessage
        responseHttpCode
        status
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TransactionListByPaymentId = /* GraphQL */ `
  query TransactionListByPaymentId(
    $paymentId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TransactionListByPaymentId(
      paymentId: $paymentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentId
        orderId
        type
        createdAt
        updatedAt
        provider
        responseContentJson
        responseMessage
        responseHttpCode
        status
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const TransactionListByOrderIdAndDate = /* GraphQL */ `
  query TransactionListByOrderIdAndDate(
    $orderId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    TransactionListByOrderIdAndDate(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentId
        orderId
        type
        createdAt
        updatedAt
        provider
        responseContentJson
        responseMessage
        responseHttpCode
        status
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLineItem = /* GraphQL */ `
  query GetLineItem($id: ID!) {
    getLineItem(id: $id) {
      id
      orderId
      productId
      productName
      qty
      qtyName
      warehouseId
      description
      price
      total
      vat
      currency
      order {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      warehouse {
        id
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      product {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLineItems = /* GraphQL */ `
  query ListLineItems(
    $filter: ModelLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        productId
        productName
        qty
        qtyName
        warehouseId
        description
        price
        total
        vat
        currency
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        warehouse {
          id
          name
          type
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      orderId
      type
      createdAt
      updatedAt
      provider
      invoiceContent
      status
      order {
        id
        type
        specialOrderId
        customerId
        createdAt
        updatedAt
        total
        subtotal
        tax
        currency
        status
        company
        firstName
        lastName
        email
        customerType
        taxId
        taxIdEu
        phone
        language
        shippingCost
        shippingCurrency
        shippingType
        shippingName
        shippingZip
        shippingCity
        shippingStreet
        shippingStreetType
        shippingHouseNumber
        shippingHouseNumberInfo
        shippingCountry
        shippingCountryCode
        shippingComment
        billingType
        billingName
        billingZip
        billingCity
        billingStreet
        billingStreetType
        billingHouseNumber
        billingCountry
        billingCountryCode
        paymentStatus
        paymentType
        shippingStatus
        customer {
          id
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          ordersByDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          ordersByStatusDate {
            items {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        lineItems {
          items {
            id
            orderId
            productId
            productName
            qty
            qtyName
            warehouseId
            description
            price
            total
            vat
            currency
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        invoices {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            provider
            invoiceContent
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        transactions {
          items {
            id
            paymentId
            orderId
            type
            createdAt
            updatedAt
            provider
            responseContentJson
            responseMessage
            responseHttpCode
            status
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        adminComments {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            comment
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        emailNotifications {
          items {
            id
            orderId
            type
            createdAt
            updatedAt
            notificationType
            mailFrom
            mailTo
            sesId
            order {
              id
              type
              specialOrderId
              customerId
              createdAt
              updatedAt
              total
              subtotal
              tax
              currency
              status
              company
              firstName
              lastName
              email
              customerType
              taxId
              taxIdEu
              phone
              language
              shippingCost
              shippingCurrency
              shippingType
              shippingName
              shippingZip
              shippingCity
              shippingStreet
              shippingStreetType
              shippingHouseNumber
              shippingHouseNumberInfo
              shippingCountry
              shippingCountryCode
              shippingComment
              billingType
              billingName
              billingZip
              billingCity
              billingStreet
              billingStreetType
              billingHouseNumber
              billingCountry
              billingCountryCode
              paymentStatus
              paymentType
              shippingStatus
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        provider
        invoiceContent
        status
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const InvoiceListByOrderId = /* GraphQL */ `
  query InvoiceListByOrderId(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    InvoiceListByOrderId(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        type
        createdAt
        updatedAt
        provider
        invoiceContent
        status
        order {
          id
          type
          specialOrderId
          customerId
          createdAt
          updatedAt
          total
          subtotal
          tax
          currency
          status
          company
          firstName
          lastName
          email
          customerType
          taxId
          taxIdEu
          phone
          language
          shippingCost
          shippingCurrency
          shippingType
          shippingName
          shippingZip
          shippingCity
          shippingStreet
          shippingStreetType
          shippingHouseNumber
          shippingHouseNumberInfo
          shippingCountry
          shippingCountryCode
          shippingComment
          billingType
          billingName
          billingZip
          billingCity
          billingStreet
          billingStreetType
          billingHouseNumber
          billingCountry
          billingCountryCode
          paymentStatus
          paymentType
          shippingStatus
          customer {
            id
            company
            firstName
            lastName
            email
            customerType
            taxId
            taxIdEu
            phone
            language
            ordersByDate {
              nextToken
              __typename
            }
            ordersByStatusDate {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          lineItems {
            items {
              id
              orderId
              productId
              productName
              qty
              qtyName
              warehouseId
              description
              price
              total
              vat
              currency
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          invoices {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              provider
              invoiceContent
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          transactions {
            items {
              id
              paymentId
              orderId
              type
              createdAt
              updatedAt
              provider
              responseContentJson
              responseMessage
              responseHttpCode
              status
              owner
              __typename
            }
            nextToken
            __typename
          }
          adminComments {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              comment
              owner
              __typename
            }
            nextToken
            __typename
          }
          emailNotifications {
            items {
              id
              orderId
              type
              createdAt
              updatedAt
              notificationType
              mailFrom
              mailTo
              sesId
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCart = /* GraphQL */ `
  query GetCart($id: ID!) {
    getCart(id: $id) {
      id
      cartItems {
        items {
          id
          cartId
          productId
          qty
          cart {
            id
            cartItems {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCarts = /* GraphQL */ `
  query ListCarts(
    $filter: ModelCartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cartItems {
          items {
            id
            cartId
            productId
            qty
            cart {
              id
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCartItem = /* GraphQL */ `
  query GetCartItem($id: ID!) {
    getCartItem(id: $id) {
      id
      cartId
      productId
      qty
      cart {
        id
        cartItems {
          items {
            id
            cartId
            productId
            qty
            cart {
              id
              createdAt
              updatedAt
              owner
              __typename
            }
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      product {
        id
        type
        createdAt
        updatedAt
        manualUpdatedAt
        ean
        name
        image
        mainImage
        mainImageOriginalWidth
        mainImageOriginalHeight
        mainImageUpdatedAt
        moreImages
        author
        binding
        bic
        countryOfOrigin
        editor
        illustrator
        edition
        illustrations
        coPublisherName
        languageOfBook
        mixedPackComponents
        nationalCurriculumKeyStage
        publicationDate
        pagination
        numberOfPages
        price
        calculatedPrices
        priceUpdatedAt
        currency
        salePercent
        salePercentWhileStocksLast
        salePercentUpdatedAt
        pageSizeDimensions
        publisherName
        yearOfPublication
        series
        seriesEditor
        subTitle
        titlePrefix
        partTitle
        translator
        partNumber
        shortDescription
        shortDescriptionTranslationHu
        returnsFlag
        returnsDate
        newIsbn
        weight
        weightMetric
        sizeDimensionX
        sizeDimensionY
        sizeDimensionZ
        orderable
        orderableInfo
        orderableDataUpdatedAt
        orderableFlag
        oldShopItemsItemId
        htmlHeadDescriptionOverwrite
        htmlHeadKeywordsAdded
        searchText
        searchTextReverse
        searchTextCategorys
        searchTextFlags
        productTemplate
        deleted
        deletedAt
        disabledSalesChannels
        categorys {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        inventories {
          items {
            productID
            warehouseID
            inventoryAmount
            warehouse {
              id
              name
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            inventoryModifiedBy
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        tags {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        producthistories {
          items {
            id
            type
            productId
            createdAt
            updatedAt
            ean
            name
            image
            mainImage
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            currency
            salePercent
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            productTemplate
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        lockedattribs {
          items {
            id
            ProductID
            lockedAttribute
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCartItems = /* GraphQL */ `
  query ListCartItems(
    $filter: ModelCartItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cartId
        productId
        qty
        cart {
          id
          cartItems {
            items {
              id
              cartId
              productId
              qty
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        product {
          id
          type
          createdAt
          updatedAt
          manualUpdatedAt
          ean
          name
          image
          mainImage
          mainImageOriginalWidth
          mainImageOriginalHeight
          mainImageUpdatedAt
          moreImages
          author
          binding
          bic
          countryOfOrigin
          editor
          illustrator
          edition
          illustrations
          coPublisherName
          languageOfBook
          mixedPackComponents
          nationalCurriculumKeyStage
          publicationDate
          pagination
          numberOfPages
          price
          calculatedPrices
          priceUpdatedAt
          currency
          salePercent
          salePercentWhileStocksLast
          salePercentUpdatedAt
          pageSizeDimensions
          publisherName
          yearOfPublication
          series
          seriesEditor
          subTitle
          titlePrefix
          partTitle
          translator
          partNumber
          shortDescription
          shortDescriptionTranslationHu
          returnsFlag
          returnsDate
          newIsbn
          weight
          weightMetric
          sizeDimensionX
          sizeDimensionY
          sizeDimensionZ
          orderable
          orderableInfo
          orderableDataUpdatedAt
          orderableFlag
          oldShopItemsItemId
          htmlHeadDescriptionOverwrite
          htmlHeadKeywordsAdded
          searchText
          searchTextReverse
          searchTextCategorys
          searchTextFlags
          productTemplate
          deleted
          deletedAt
          disabledSalesChannels
          categorys {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          inventories {
            items {
              productID
              warehouseID
              inventoryAmount
              inventoryModifiedBy
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          tags {
            items {
              id
              productId
              tagId
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          producthistories {
            items {
              id
              type
              productId
              createdAt
              updatedAt
              ean
              name
              image
              mainImage
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              currency
              salePercent
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              productTemplate
              owner
              __typename
            }
            nextToken
            __typename
          }
          lockedattribs {
            items {
              id
              ProductID
              lockedAttribute
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWebshopMenu = /* GraphQL */ `
  query GetWebshopMenu($id: ID!) {
    getWebshopMenu(id: $id) {
      id
      createdAt
      updatedAt
      parentID
      categoryID
      name
      description
      lang
      slug
      order
      root
      show
      parent {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      children {
        items {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        nextToken
        __typename
      }
      category {
        id
        name
        numberOfProducts
        createdAt
        updatedAt
        products {
          items {
            id
            ProductID
            categoryID
            productPrice
            productPublicationDate
            productOrderable
            productOrderableInfo
            productSalePercent
            type
            createdAt
            updatedAt
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        webshopmenus {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listWebshopMenus = /* GraphQL */ `
  query ListWebshopMenus(
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebshopMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WebshopMenuByParent = /* GraphQL */ `
  query WebshopMenuByParent(
    $parentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebshopMenuByParent(
      parentID: $parentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WebshopMenuByParentByOrder = /* GraphQL */ `
  query WebshopMenuByParentByOrder(
    $parentID: ID!
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebshopMenuByParentByOrder(
      parentID: $parentID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WebshopMenuByCategory = /* GraphQL */ `
  query WebshopMenuByCategory(
    $categoryID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebshopMenuByCategory(
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WebshopMenuByLang = /* GraphQL */ `
  query WebshopMenuByLang(
    $lang: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebshopMenuByLang(
      lang: $lang
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WebshopMenuByLangBySlug = /* GraphQL */ `
  query WebshopMenuByLangBySlug(
    $lang: String!
    $slug: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebshopMenuByLangBySlug(
      lang: $lang
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const WebshopMenuBySlug = /* GraphQL */ `
  query WebshopMenuBySlug(
    $slug: String!
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    WebshopMenuBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        parentID
        categoryID
        name
        description
        lang
        slug
        order
        root
        show
        parent {
          id
          createdAt
          updatedAt
          parentID
          categoryID
          name
          description
          lang
          slug
          order
          root
          show
          parent {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          children {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          category {
            id
            name
            numberOfProducts
            createdAt
            updatedAt
            products {
              nextToken
              __typename
            }
            webshopmenus {
              nextToken
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        children {
          items {
            id
            createdAt
            updatedAt
            parentID
            categoryID
            name
            description
            lang
            slug
            order
            root
            show
            parent {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            children {
              nextToken
              __typename
            }
            category {
              id
              name
              numberOfProducts
              createdAt
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          nextToken
          __typename
        }
        category {
          id
          name
          numberOfProducts
          createdAt
          updatedAt
          products {
            items {
              id
              ProductID
              categoryID
              productPrice
              productPublicationDate
              productOrderable
              productOrderableInfo
              productSalePercent
              type
              createdAt
              updatedAt
              owner
              __typename
            }
            nextToken
            __typename
          }
          webshopmenus {
            items {
              id
              createdAt
              updatedAt
              parentID
              categoryID
              name
              description
              lang
              slug
              order
              root
              show
              owner
              __typename
            }
            nextToken
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      address1
      address2
      city
      country
      countryCode
      company
      name
      firstName
      lastName
      latitude
      longitude
      phone
      province
      provinceCode
      zip
      street
      houseNumber
      streetType
      defaultAddress
      addressType
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address1
        address2
        city
        country
        countryCode
        company
        name
        firstName
        lastName
        latitude
        longitude
        phone
        province
        provinceCode
        zip
        street
        houseNumber
        streetType
        defaultAddress
        addressType
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscountGlobal = /* GraphQL */ `
  query GetDiscountGlobal($id: ID!) {
    getDiscountGlobal(id: $id) {
      id
      discountAdminName
      discountMultiplier
      createdAt
      updatedAt
      type
      owner
      __typename
    }
  }
`;
export const listDiscountGlobals = /* GraphQL */ `
  query ListDiscountGlobals(
    $filter: ModelDiscountGlobalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscountGlobals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        discountAdminName
        discountMultiplier
        createdAt
        updatedAt
        type
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListDiscountGlobalByDate = /* GraphQL */ `
  query ListDiscountGlobalByDate(
    $type: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountGlobalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListDiscountGlobalByDate(
      type: $type
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        discountAdminName
        discountMultiplier
        createdAt
        updatedAt
        type
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfitMargin = /* GraphQL */ `
  query GetProfitMargin($id: ID!) {
    getProfitMargin(id: $id) {
      id
      profitMarginMultiplier
      adminDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProfitMargins = /* GraphQL */ `
  query ListProfitMargins(
    $filter: ModelProfitMarginFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfitMargins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        profitMarginMultiplier
        adminDescription
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShippingPrice = /* GraphQL */ `
  query GetShippingPrice($id: ID!) {
    getShippingPrice(id: $id) {
      id
      shippingTypeAdminName
      shippingType
      price
      currency
      callForPrice
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listShippingPrices = /* GraphQL */ `
  query ListShippingPrices(
    $filter: ModelShippingPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShippingPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shippingTypeAdminName
        shippingType
        price
        currency
        callForPrice
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBicCodes = /* GraphQL */ `
  query GetBicCodes($id: ID!) {
    getBicCodes(id: $id) {
      id
      type
      qualifier
      name
      categoryId
      categories {
        items {
          id
          bicCodeId
          categoryId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBicCodes = /* GraphQL */ `
  query ListBicCodes(
    $filter: ModelBicCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBicCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        qualifier
        name
        categoryId
        categories {
          items {
            id
            bicCodeId
            categoryId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListBicCodesByQualifier = /* GraphQL */ `
  query ListBicCodesByQualifier(
    $type: String!
    $qualifier: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBicCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListBicCodesByQualifier(
      type: $type
      qualifier: $qualifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        qualifier
        name
        categoryId
        categories {
          items {
            id
            bicCodeId
            categoryId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const BicCodesByQualifier = /* GraphQL */ `
  query BicCodesByQualifier(
    $qualifier: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBicCodesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BicCodesByQualifier(
      qualifier: $qualifier
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        qualifier
        name
        categoryId
        categories {
          items {
            id
            bicCodeId
            categoryId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchBicCodes = /* GraphQL */ `
  query SearchBicCodes(
    $filter: SearchableBicCodesFilterInput
    $sort: [SearchableBicCodesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBicCodesAggregationInput]
  ) {
    searchBicCodes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        type
        qualifier
        name
        categoryId
        categories {
          items {
            id
            bicCodeId
            categoryId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getBicCodeCategoryPair = /* GraphQL */ `
  query GetBicCodeCategoryPair($id: ID!) {
    getBicCodeCategoryPair(id: $id) {
      id
      bicCodeId
      categoryId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBicCodeCategoryPairs = /* GraphQL */ `
  query ListBicCodeCategoryPairs(
    $filter: ModelBicCodeCategoryPairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBicCodeCategoryPairs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bicCodeId
        categoryId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const BicCodeCategoryPairByQualifier = /* GraphQL */ `
  query BicCodeCategoryPairByQualifier(
    $bicCodeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBicCodeCategoryPairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BicCodeCategoryPairByQualifier(
      bicCodeId: $bicCodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bicCodeId
        categoryId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const BicCodeCategoryPairByCategory = /* GraphQL */ `
  query BicCodeCategoryPairByCategory(
    $categoryId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBicCodeCategoryPairFilterInput
    $limit: Int
    $nextToken: String
  ) {
    BicCodeCategoryPairByCategory(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        bicCodeId
        categoryId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatProductCountSum = /* GraphQL */ `
  query GetStatProductCountSum($id: ID!) {
    getStatProductCountSum(id: $id) {
      id
      type
      numberOfProducts
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStatProductCountSums = /* GraphQL */ `
  query ListStatProductCountSums(
    $filter: ModelStatProductCountSumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatProductCountSums(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        numberOfProducts
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListStatProductCountSumByDate = /* GraphQL */ `
  query ListStatProductCountSumByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStatProductCountSumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListStatProductCountSumByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        numberOfProducts
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatCustomerCountSum = /* GraphQL */ `
  query GetStatCustomerCountSum($id: ID!) {
    getStatCustomerCountSum(id: $id) {
      id
      numberOfCustomers
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStatCustomerCountSums = /* GraphQL */ `
  query ListStatCustomerCountSums(
    $filter: ModelStatCustomerCountSumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatCustomerCountSums(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberOfCustomers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatOrderCountSum = /* GraphQL */ `
  query GetStatOrderCountSum($id: ID!) {
    getStatOrderCountSum(id: $id) {
      id
      numberOfOrders
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStatOrderCountSums = /* GraphQL */ `
  query ListStatOrderCountSums(
    $filter: ModelStatOrderCountSumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatOrderCountSums(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberOfOrders
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStatOrderLineItemCountSum = /* GraphQL */ `
  query GetStatOrderLineItemCountSum($id: ID!) {
    getStatOrderLineItemCountSum(id: $id) {
      id
      numberOfOrderLineItems
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listStatOrderLineItemCountSums = /* GraphQL */ `
  query ListStatOrderLineItemCountSums(
    $filter: ModelStatOrderLineItemCountSumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStatOrderLineItemCountSums(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        numberOfOrderLineItems
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      tag
      products {
        items {
          id
          productId
          tagId
          product {
            id
            type
            createdAt
            updatedAt
            manualUpdatedAt
            ean
            name
            image
            mainImage
            mainImageOriginalWidth
            mainImageOriginalHeight
            mainImageUpdatedAt
            moreImages
            author
            binding
            bic
            countryOfOrigin
            editor
            illustrator
            edition
            illustrations
            coPublisherName
            languageOfBook
            mixedPackComponents
            nationalCurriculumKeyStage
            publicationDate
            pagination
            numberOfPages
            price
            calculatedPrices
            priceUpdatedAt
            currency
            salePercent
            salePercentWhileStocksLast
            salePercentUpdatedAt
            pageSizeDimensions
            publisherName
            yearOfPublication
            series
            seriesEditor
            subTitle
            titlePrefix
            partTitle
            translator
            partNumber
            shortDescription
            shortDescriptionTranslationHu
            returnsFlag
            returnsDate
            newIsbn
            weight
            weightMetric
            sizeDimensionX
            sizeDimensionY
            sizeDimensionZ
            orderable
            orderableInfo
            orderableDataUpdatedAt
            orderableFlag
            oldShopItemsItemId
            htmlHeadDescriptionOverwrite
            htmlHeadKeywordsAdded
            searchText
            searchTextReverse
            searchTextCategorys
            searchTextFlags
            productTemplate
            deleted
            deletedAt
            disabledSalesChannels
            categorys {
              nextToken
              __typename
            }
            inventories {
              nextToken
              __typename
            }
            tags {
              nextToken
              __typename
            }
            producthistories {
              nextToken
              __typename
            }
            lockedattribs {
              nextToken
              __typename
            }
            owner
            __typename
          }
          tag {
            id
            tag
            products {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag
        products {
          items {
            id
            productId
            tagId
            product {
              id
              type
              createdAt
              updatedAt
              manualUpdatedAt
              ean
              name
              image
              mainImage
              mainImageOriginalWidth
              mainImageOriginalHeight
              mainImageUpdatedAt
              moreImages
              author
              binding
              bic
              countryOfOrigin
              editor
              illustrator
              edition
              illustrations
              coPublisherName
              languageOfBook
              mixedPackComponents
              nationalCurriculumKeyStage
              publicationDate
              pagination
              numberOfPages
              price
              calculatedPrices
              priceUpdatedAt
              currency
              salePercent
              salePercentWhileStocksLast
              salePercentUpdatedAt
              pageSizeDimensions
              publisherName
              yearOfPublication
              series
              seriesEditor
              subTitle
              titlePrefix
              partTitle
              translator
              partNumber
              shortDescription
              shortDescriptionTranslationHu
              returnsFlag
              returnsDate
              newIsbn
              weight
              weightMetric
              sizeDimensionX
              sizeDimensionY
              sizeDimensionZ
              orderable
              orderableInfo
              orderableDataUpdatedAt
              orderableFlag
              oldShopItemsItemId
              htmlHeadDescriptionOverwrite
              htmlHeadKeywordsAdded
              searchText
              searchTextReverse
              searchTextCategorys
              searchTextFlags
              productTemplate
              deleted
              deletedAt
              disabledSalesChannels
              owner
              __typename
            }
            tag {
              id
              tag
              createdAt
              updatedAt
              owner
              __typename
            }
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostcode = /* GraphQL */ `
  query GetPostcode($id: ID!) {
    getPostcode(id: $id) {
      id
      postcode
      settlement
      country
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPostcodes = /* GraphQL */ `
  query ListPostcodes(
    $filter: ModelPostcodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostcodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postcode
        settlement
        country
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMailqueue = /* GraphQL */ `
  query GetMailqueue($id: ID!) {
    getMailqueue(id: $id) {
      id
      seslambdaname
      expirationUnixTime
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMailqueues = /* GraphQL */ `
  query ListMailqueues(
    $filter: ModelMailqueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMailqueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        seslambdaname
        expirationUnixTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesChannel = /* GraphQL */ `
  query GetSalesChannel($id: ID!) {
    getSalesChannel(id: $id) {
      id
      privateTitle
      privateOptions
      privateSort
      publicTitle
      publicOptions
      salesChannelType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesChannels = /* GraphQL */ `
  query ListSalesChannels(
    $filter: ModelSalesChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        privateTitle
        privateOptions
        privateSort
        publicTitle
        publicOptions
        salesChannelType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListSalesChannelsBySalesChannelType = /* GraphQL */ `
  query ListSalesChannelsBySalesChannelType(
    $salesChannelType: SalesChannelType!
    $sortDirection: ModelSortDirection
    $filter: ModelSalesChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListSalesChannelsBySalesChannelType(
      salesChannelType: $salesChannelType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privateTitle
        privateOptions
        privateSort
        publicTitle
        publicOptions
        salesChannelType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWebshopContent = /* GraphQL */ `
  query GetWebshopContent($id: ID!) {
    getWebshopContent(id: $id) {
      id
      componentId
      showFrom
      showFromInt
      showTo
      showToInt
      content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWebshopContents = /* GraphQL */ `
  query ListWebshopContents(
    $filter: ModelWebshopContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebshopContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        componentId
        showFrom
        showFromInt
        showTo
        showToInt
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ListWebshopContentsByCreatedAt = /* GraphQL */ `
  query ListWebshopContentsByCreatedAt(
    $componentId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWebshopContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ListWebshopContentsByCreatedAt(
      componentId: $componentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        componentId
        showFrom
        showFromInt
        showTo
        showToInt
        content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLogPasswordReminder = /* GraphQL */ `
  query GetLogPasswordReminder($id: ID!) {
    getLogPasswordReminder(id: $id) {
      id
      email
      info
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLogPasswordReminders = /* GraphQL */ `
  query ListLogPasswordReminders(
    $filter: ModelLogPasswordReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLogPasswordReminders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        info
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchLogPasswordReminders = /* GraphQL */ `
  query SearchLogPasswordReminders(
    $filter: SearchableLogPasswordReminderFilterInput
    $sort: [SearchableLogPasswordReminderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableLogPasswordReminderAggregationInput]
  ) {
    searchLogPasswordReminders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        info
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getBatchRunningProcesses = /* GraphQL */ `
  query GetBatchRunningProcesses($id: ID!) {
    getBatchRunningProcesses(id: $id) {
      id
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBatchRunningProcesses = /* GraphQL */ `
  query ListBatchRunningProcesses(
    $filter: ModelBatchRunningProcessesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchRunningProcesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductGoogleContentApiImport = /* GraphQL */ `
  query GetProductGoogleContentApiImport($id: ID!) {
    getProductGoogleContentApiImport(id: $id) {
      id
      ean
      name
      mainImage
      author
      languageOfBook
      publicationDate
      price
      currency
      calculatedPrice
      calculatedPriceCurrency
      salePercent
      shortDescription
      orderable
      orderableInfo
      nextPlannedSync
      expirationTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProductGoogleContentApiImports = /* GraphQL */ `
  query ListProductGoogleContentApiImports(
    $filter: ModelProductGoogleContentApiImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductGoogleContentApiImports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ean
        name
        mainImage
        author
        languageOfBook
        publicationDate
        price
        currency
        calculatedPrice
        calculatedPriceCurrency
        salePercent
        shortDescription
        orderable
        orderableInfo
        nextPlannedSync
        expirationTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ProductGoogleContentApiImportByEan = /* GraphQL */ `
  query ProductGoogleContentApiImportByEan(
    $ean: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProductGoogleContentApiImportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ProductGoogleContentApiImportByEan(
      ean: $ean
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ean
        name
        mainImage
        author
        languageOfBook
        publicationDate
        price
        currency
        calculatedPrice
        calculatedPriceCurrency
        salePercent
        shortDescription
        orderable
        orderableInfo
        nextPlannedSync
        expirationTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProductGoogleContentApiImports = /* GraphQL */ `
  query SearchProductGoogleContentApiImports(
    $filter: SearchableProductGoogleContentApiImportFilterInput
    $sort: [SearchableProductGoogleContentApiImportSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProductGoogleContentApiImportAggregationInput]
  ) {
    searchProductGoogleContentApiImports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        ean
        name
        mainImage
        author
        languageOfBook
        publicationDate
        price
        currency
        calculatedPrice
        calculatedPriceCurrency
        salePercent
        shortDescription
        orderable
        orderableInfo
        nextPlannedSync
        expirationTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getMailchimpAudienceList = /* GraphQL */ `
  query GetMailchimpAudienceList {
    getMailchimpAudienceList
  }
`;
export const getMailchimpListMembersById = /* GraphQL */ `
  query GetMailchimpListMembersById($listId: Int) {
    getMailchimpListMembersById(listId: $listId)
  }
`;
export const isEmailExists = /* GraphQL */ `
  query IsEmailExists($emailaddress: String) {
    isEmailExists(emailaddress: $emailaddress)
  }
`;
export const searchProductsInOpenSearch = /* GraphQL */ `
  query SearchProductsInOpenSearch(
    $searchstring: String!
    $filter: AWSJSON
    $limit: Int
    $from: Int
  ) {
    searchProductsInOpenSearch(
      searchstring: $searchstring
      filter: $filter
      limit: $limit
      from: $from
    )
  }
`;
export const barionGetPaymentState = /* GraphQL */ `
  query BarionGetPaymentState($paymentId: String) {
    barionGetPaymentState(paymentId: $paymentId)
  }
`;
export const getCognitoUserList = /* GraphQL */ `
  query GetCognitoUserList {
    getCognitoUserList
  }
`;
export const getCognitoAdminList = /* GraphQL */ `
  query GetCognitoAdminList {
    getCognitoAdminList
  }
`;
